import React, { useState } from 'react'
import {
  IProductInList,
  useEvents,
  useProductsSearch,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import {
  useProdutsCarousel,
  useShelvesData
} from '../helpers/useProdutsCarousel'
import { ContentCarousel } from '../ContentCarousel'
import { CarouselFromContentful } from 'shared/interfaces/Home'
import { CustomTitles } from 'components/Home'
import { clusterParams } from '../../../shared/utils'
import { useLocalAddress } from 'shared/utils/hooks/useLocalAddress'

export interface ProductCarouselProps {
  clusterData: CarouselFromContentful
  isMobile?: boolean
  seeAllUrl?: string
  titleSizes: CustomTitles
  typeCarousel?: string
  handleOpenMyListsModal: (item: IProductInList) => void
  type?: string
}

export const CarouselCollectionsComponent = ({
  clusterData,
  isMobile,
  titleSizes,
  typeCarousel,
  handleOpenMyListsModal
}: ProductCarouselProps) => {
  // use
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const { hasLocalAddress } = useLocalAddress()

  const clusterId = clusterData?.['reference']?.replace(
    'productClusterIds:',
    ''
  )
  const clusterParam = clusterParams({
    clusterNames: clusterData?.['reference']?.['clusterName'],
    clusterId: clusterData?.['reference']?.replace('productClusterIds:', '')
  })

  const productsCluster = useProductsSearch({
    ...clusterParam,
    clusterId,
    from: '0',
    to: '14',
    reactQuery: {
      queryKey: clusterParam?.clusterNames || clusterParam?.clusterId,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })
  const { availableProducts: products = [] } = productsCluster?.data || {}

  const {
    linkFontSize,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    carouselHeaderPadding,
    spacerBelow
  } = useProdutsCarousel({ isMobile })

  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading,
    products,
    getListId: () => {
      return `Home_${clusterData['title']}`
    },
    getListName: () => {
      return `Home/${clusterData['title']}`
    },
    hasLocalAddress
  })

  const productsMemo = useShelvesData(shelvesData, isMobile) || []

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  const carouselShelData = {
    positionContainer,
    productsMemo,
    itemsToShow,
    itemsToSlide,
    spacerBelow,
    handleOnClick,
    handleAdd,
    handleRemove,
    handleChange,
    handleOpenMyListsModal
  }

  const isLoading: boolean = productsCluster.isLoading

  if (productsMemo?.length === 0) {
    return null
  }

  return (
    <ContentCarousel
      carouselHeaderPadding={carouselHeaderPadding}
      carouselShelData={carouselShelData}
      clusterData={clusterData}
      isLoading={isLoading}
      isMobile={isMobile}
      linkFontSize={linkFontSize}
      titleSizes={titleSizes}
      typeCarousel={typeCarousel}
    />
  )
}

function handleCarousel(prevState, nextState) {
  return prevState.clusterData === nextState.clusterData
}
export const CarouselCollections = React.memo(
  CarouselCollectionsComponent,
  handleCarousel
)
