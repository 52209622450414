import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import {
  Backbone,
  BackboneShelves,
  Column,
  FiltersProps,
  HandleClickCategoryPill,
  Row,
  SelectByOptionsV2,
  Spacer,
  Place,
  CategoriesBarProps,
  SuggestionsPills
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { AvailableProduct } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import { Product } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import {
  UseQueryResult,
  datalayerSimpleEvent,
  isValidArrayWithData,
  sleep
} from '@smu-chile/pkg-unimarc-hooks'
import {
  CustomPagination,
  FilterSelectorProps,
  ImagesProps,
  ParagraphProps,
  ShelvesProps
} from 'components'
import {
  orderByOptions,
  returnMatchedKeyName,
  setPageLocation
} from 'shared/helpers'
import { TMonetiseBannerDataProps } from '../MonetiseBanner/index'
import { CustomHeader } from './CustomHeader'
import { useAddProductToList } from 'shared/utils/hooks'
import { AddToListModalWrapper } from 'components/AddToListModalWrapper'
import {
  ICategoryResponse,
  IPostFacetsResponse
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostFacets'
import { ICategories } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICategories'
import { MonetiseBannerPLP } from 'components/ProductListPage/helpers'
import { WithoutProductsProps } from './WithoutProducts'

const SelectOrderBy = dynamic<SelectByOptionsV2>(
  import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.SelectOrderByV2
  })
)
const Shelves = dynamic<ShelvesProps>(
  import('components').then((mod) => {
    return mod.Shelves
  })
)
const FacetFilters = dynamic<FiltersProps>(
  import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.FacetFilters
  })
)
const CategoriesBar = dynamic<CategoriesBarProps>(
  import('@smu-chile/pkg-unimarc-components').then((mod) => {
    return mod.CategoriesBar
  })
)
const WithoutProducts = dynamic<WithoutProductsProps>(
  import('./WithoutProducts').then((mod) => {
    return mod.WithoutProducts
  })
)

export type GeneralObjectCategories = {
  label: string
  url?: string
  oneClick?: (url: string) => void
}

export type propsNavigation = {
  currentPage: number
  pageQuantity: number
  isVisableButtonLeft: boolean
  isVisableButtonRight: boolean
  isVisableButtons: boolean
  items: (string | number)[]
  nextPage: () => void
  prevPage: () => void
  setPage: (value: number) => void
}

export interface BodyPageProductsProps {
  amountOfProducts?: number
  imageAndes?: string
  bannerAndesMl?: TMonetiseBannerDataProps
  bannerImages?: ImagesProps[]
  breadcrumbData?: GeneralObjectCategories[]
  categoriesId?: string
  clusterName?: string
  contentLegalParagraph?: ParagraphProps[]
  contentLegalTitle?: string
  continuosFetching?: boolean
  facetsFilterV2?: IPostFacetsResponse
  filterData?: FiltersProps
  filterSelector?: FilterSelectorProps
  firstLoad?: number
  isLoadingBannerAndes?: boolean
  isMobile?: boolean
  key?: string
  orderForm?: string
  padding?: string
  place?: Place
  products?: Partial<AvailableProduct>[] & Partial<Product>[]
  propsNavigation?: propsNavigation
  searchBannerData?: TMonetiseBannerDataProps
  searchSuggestions?: string[]
  selectedRadio?: string
  subCategories?: Omit<GeneralObjectCategories, 'oneClick'>[]
  reference?: string
  resultAisles?: UseQueryResult<ICategories>
  title?: string
  totalQuantity?: string | number
  urlLinkOffer?: string
  showModal?: boolean
  disabledButton?: boolean
  hasFacets?: boolean
  query?: string
  urlLink?: string
  showFiltersModal?: boolean
  selectedTab?: number
  handleToggleFiltersModal?: () => void
  setSelectedTab?: Dispatch<SetStateAction<number>>
  onClickCategoryPill?: HandleClickCategoryPill
  handleFirstLoad?: () => void
  onClickFilter?: (orderValue: string) => void
  onClickRadioOrderByOption?: (radioValue: string) => void
  onClickSuggestion?: (suggestion: string) => void
  handleOnSelectRange?: (min: number, max: number) => void
  handleOnTogglePromotions?: (value: boolean) => void
  setCountLimit?: () => void
  onApplyFilterModal?: () => void
  handleOpenFiltersModal?: Dispatch<SetStateAction<boolean>>
}

const allowedQueryParams = [
  'orderBy',
  'page',
  'q',
  'filter',
  'brands',
  'promotionsOnly',
  'volume',
  'warningStamps',
  'priceRange',
  'priceRangeFrom',
  'priceRangeTo',
  'container',
  'format',
  'foodWorld',
  'warningStamps',
  'suggestions'
]

export const formatUrlParams = (
  queryParams: Record<string, string | string[]>,
  allowedQueryParams: string[] = []
): string => {
  // create a filtered copy of queryParams, only allowing keys present in `allowedQueryParams`
  const filteredParams = Object.entries(queryParams)
    .filter(([key]) => {
      return allowedQueryParams.includes(key)
    }) // Keep only keys that are in `allowedQueryParams`
    .reduce<Record<string, string | string[]>>((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {})

  // ensure that the `page` parameter only has the last value if it's a comma-separated list
  if (filteredParams.page) {
    const pageValue = Array.isArray(filteredParams.page)
      ? filteredParams.page[filteredParams.page.length - 1]
      : filteredParams.page

    // If the value of `page` is a string, split by commas and take the last value
    filteredParams.page = pageValue.split(',').pop() || ''
  }

  // temporarily remove `page` from `filteredParams` to add it at the end of the query string
  const { page, ...restParams } = filteredParams

  // convert the filtered parameters to a `key=value` string format
  const urlParams = Object.entries(restParams)
    .map(([key, value]) => {
      const formattedValue = Array.isArray(value) ? value.join(',') : value
      return `${key}=${formattedValue}`
    })
    .join('&')

  // append `page` at the end if it is present
  const finalUrlParams = page ? `${urlParams}&page=${page}` : urlParams

  return finalUrlParams
}

export const BodyPageProducts = ({
  bannerAndesMl,
  bannerImages,
  breadcrumbData,
  categoriesId,
  clusterName,
  continuosFetching,
  facetsFilterV2,
  filterData,
  firstLoad,
  hasFacets,
  isLoadingBannerAndes,
  isMobile,
  place,
  products,
  amountOfProducts,
  propsNavigation,
  reference,
  resultAisles,
  searchSuggestions,
  selectedRadio,
  subCategories = [],
  title,
  totalQuantity,
  urlLink,
  urlLinkOffer,
  onClickCategoryPill,
  onClickFilter,
  onClickSuggestion,
  handleFirstLoad,
  handleOpenFiltersModal,
  ...props
}: BodyPageProductsProps) => {
  // use
  const router = useRouter()
  const filteredKeys = Object.keys(router?.query).filter((key) => {
    return key !== 'q' && key !== 'suggestions'
  })

  const hasFiltersApplied = filteredKeys.length > 0
  const [orderBySelected, setOrderBySelected] = useState(() => {
    return router?.query?.orderBy?.toString() ?? 'OrderByDefault'
  })
  const isSearchSuggestionsPage = Boolean(router?.query?.suggestions)
  const [showFacetsIfProductsExist, setShowFacetsIfProductsExist] =
    useState(true)
  // primitive
  const justifyPaginationEnd = isMobile ? 'center' : 'end'
  // UI
  const [categoryFacets, setCategoryFacets] = useState<
    Record<string, ICategoryResponse>
  >({})

  const showFacetFilters =
    !isMobile && showFacetsIfProductsExist && !router?.query?.brand
  const showWithoutProducts =
    !filterData?.isLoadingFacets && !isValidArrayWithData(products)
  const showShelves = isValidArrayWithData(products)
  const showLoadingShelves =
    filterData?.isLoadingFacets && !isValidArrayWithData(products)

  const urlParams = useMemo(() => {
    return formatUrlParams(router?.query || {}, allowedQueryParams)
  }, [router.query])

  const navigationProps = {
    ...propsNavigation,
    isLoadingPage: filterData?.isLoadingFacets,
    path: router?.asPath?.replace(/\?.*/, ''),
    place,
    urlParams
  }

  const {
    createListButton,
    emptyOrangeImage,
    innerWidth,
    listData,
    notificationAddToList,
    openModalCreateList,
    selectedListName,
    handleAddProductsToList,
    handleCreateNewList,
    handleOnClosed,
    handleOpenMyListsModal,
    setCreateListButton,
    setNotificationAddToList,
    setOpenModalCreateList
  } = useAddProductToList()

  const handleFilteringTags = () => {
    const { brand, format } = router?.query || {}

    const returnFilter = (filterValue: string): string[] => {
      return filterValue ? [filterValue] : []
    }

    datalayerSimpleEvent({
      event: 'interacciones_filtro',
      eventAction: setPageLocation(),
      eventCategory: 'filtros',
      eventLabel: [
        'mundo de alimentación',
        brand && 'marca',
        format && 'formato'
      ].filter(Boolean),
      filtros: {
        marcas: returnFilter(brand?.toString()),
        formato: returnFilter(format?.toString()),
        mundo_alim: router?.query?.categories?.slice(1)
      }
    })
  }

  useEffect(() => {
    const sleepFirstLoad = async () => {
      await sleep(500)
      handleFirstLoad()
    }

    if (
      isMobile &&
      (router?.query?.page === '1' ||
        typeof router?.query?.page === 'undefined') &&
      router?.route?.includes('category')
    ) {
      sleepFirstLoad()
    }
  }, [])

  useEffect(() => {
    const newOrderBy = router?.query?.orderBy?.toString() ?? 'OrderByDefault'
    setOrderBySelected(newOrderBy)
    if (router?.query?.orderBy !== undefined) {
      datalayerSimpleEvent({
        event: 'interacciones_filtro',
        eventAction: 'seleccionar',
        eventCategory: 'ordenamiento',
        eventLabel: returnMatchedKeyName(router?.query?.orderBy?.toString())
      })
    }
  }, [router?.query?.orderBy])

  useEffect(() => {
    const isCategory = isValidArrayWithData(router?.query?.categories)
    if (!isCategory || filterData?.isLoadingFacets) return

    handleFilteringTags()
  }, [])

  // check when the products are not available to notify useFilter hook
  useEffect(() => {
    if (!filterData?.isLoadingFacets) {
      const hasProducts = isValidArrayWithData(products)
      filterData?.setWithoutProducts(!hasProducts)
      setShowFacetsIfProductsExist(hasProducts)
    }
  }, [products, filterData?.isLoadingFacets])

  // set category facets
  useEffect(() => {
    if (!facetsFilterV2) return
    const { category1, category2, category3 } = facetsFilterV2 || {}
    setCategoryFacets({ category1, category2, category3 })
  }, [facetsFilterV2])

  if (isMobile && firstLoad === 1) {
    return (
      <>
        <Row
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-background-white')}
          isWrap
          padding='12px'
        >
          <CustomHeader
            breadcrumbData={breadcrumbData}
            isLoadingFacets={filterData?.isLoadingFacets}
            isMobile={isMobile}
            selectedRadio={selectedRadio}
            title={title}
            totalQuantity={totalQuantity}
          />
        </Row>
        {/* mobile categories pills filter */}
        {isValidArrayWithData(facetsFilterV2) &&
          subCategories &&
          !router?.query?.brand && (
            <CategoriesBar
              categoriesFacetsV2={categoryFacets}
              isLoading={filterData?.isLoadingFacets}
              isMobile={isMobile}
              onClick={onClickCategoryPill}
              place={place}
            />
          )}
        <BackboneShelves shelvesForRow={4} />
        <Spacer.Horizontal size={32} />
        <Row justifyContent={justifyPaginationEnd}>
          <Row customWidth='25'>
            <Backbone
              borderRadius='20px'
              height={30}
            />
          </Row>
        </Row>
        <Spacer.Horizontal size={32} />
      </>
    )
  }

  return (
    <>
      <AddToListModalWrapper
        createListButton={createListButton}
        emptyOrangeImage={emptyOrangeImage}
        handleAddProductsToList={handleAddProductsToList}
        handleCreateNewList={handleCreateNewList}
        handleOnClosed={handleOnClosed}
        innerWidth={innerWidth}
        listData={listData}
        notificationAddToList={notificationAddToList}
        openModalCreateList={openModalCreateList}
        selectedListName={selectedListName}
        setCreateListButton={setCreateListButton}
        setNotificationAddToList={setNotificationAddToList}
        setOpenModalCreateList={setOpenModalCreateList}
      />

      {/*Pills sections for suggestionsPage in mobile */}
      {isMobile &&
        isValidArrayWithData(searchSuggestions) &&
        isValidArrayWithData(products) &&
        isSearchSuggestionsPage && (
          <>
            <Spacer.Horizontal size={12} />
            <SuggestionsPills
              isMobile={isMobile}
              onClickSuggestion={onClickSuggestion}
              queryString={
                Array.isArray(router.query.q)
                  ? router.query.q[0]
                  : router.query.q
              }
              suggestions={searchSuggestions}
            />
            <Spacer.Horizontal size={12} />
          </>
        )}
      {isValidArrayWithData(products) && (
        <CustomHeader
          breadcrumbData={breadcrumbData}
          handleOpenFiltersModal={handleOpenFiltersModal}
          hasFacets={hasFacets}
          isLoadingFacets={filterData?.isLoadingFacets}
          isMobile={isMobile}
          isSuggestionsPage={isSearchSuggestionsPage}
          selectedRadio={selectedRadio}
          title={title}
          totalQuantity={totalQuantity}
        />
      )}

      {/* pills sections for suggestionsPage in desktop */}
      {!isMobile &&
        isValidArrayWithData(searchSuggestions) &&
        isValidArrayWithData(products) &&
        isSearchSuggestionsPage && (
          <>
            <Spacer.Horizontal size={20} />
            <SuggestionsPills
              isMobile={isMobile}
              onClickSuggestion={onClickSuggestion}
              queryString={
                Array.isArray(router.query.q)
                  ? router.query.q[0]
                  : router.query.q
              }
              suggestions={searchSuggestions}
            />
          </>
        )}

      {/* categories pills filter */}
      {!isSearchSuggestionsPage &&
        subCategories &&
        !router?.query?.brand &&
        isValidArrayWithData(products) && (
          <CategoriesBar
            categoriesFacetsV2={categoryFacets}
            isLoading={filterData?.isLoadingFacets}
            isMobile={isMobile}
            onClick={onClickCategoryPill}
            place={place}
          />
        )}

      {/* desktop orderBy and Pagination */}
      {!isMobile && (
        <>
          <Spacer.Horizontal size={isSearchSuggestionsPage ? 20 : 12} />
          <Row justifyContent='end'>
            {!router?.query?.brand && isValidArrayWithData(products) && (
              <SelectOrderBy
                isSuggestionsPage={isSearchSuggestionsPage}
                onClick={onClickFilter}
                optionSelected={orderBySelected}
                options={orderByOptions}
              />
            )}
            {isValidArrayWithData(products) && (
              <CustomPagination {...navigationProps} />
            )}
          </Row>
          <Spacer.Horizontal size={32} />
        </>
      )}
      {/* mobile banner PLP */}
      {isMobile && !isLoadingBannerAndes && (bannerAndesMl || bannerImages) && (
        <MonetiseBannerPLP
          data={bannerAndesMl}
          imageAndes={bannerAndesMl?.imageUrl}
          images={bannerImages}
          isLoadingPage={filterData?.isLoadingFacets}
          key={categoriesId}
          padding='0px 12px'
          resultAisles={resultAisles}
          urlLink={bannerAndesMl?.redirectUrl || urlLink || urlLinkOffer}
        />
      )}
      {/* desktop facets filters and product's shelves */}
      <Row
        padding={isMobile ? '12px 0px 0px 0px' : ''}
        tagName='section'
      >
        {showFacetFilters && (
          <Column
            margin='0 16px 0 0'
            maxWidth='258px'
            tagName='aside'
          >
            <FacetFilters
              {...filterData}
              amountOfProducts={amountOfProducts}
            />
          </Column>
        )}
        {/* without products component */}
        {showWithoutProducts && (
          <Column margin='0 0 0 4px'>
            <WithoutProducts
              hasFiltersApplied={hasFiltersApplied}
              isMobile={isMobile}
            />
          </Column>
        )}
        {showShelves && (
          <Column margin='0 0 0 4px'>
            <Shelves
              clusterName={clusterName}
              continuosFetching={continuosFetching}
              handleOpenMyListsModal={handleOpenMyListsModal}
              isMobile={isMobile}
              products={products}
              reference={reference}
              {...props}
            />
          </Column>
        )}

        {showLoadingShelves && <BackboneShelves shelvesForRow={4} />}
      </Row>
      <Spacer.Horizontal size={32} />
      {/* desktop and mobile pagination */}
      <Row justifyContent={justifyPaginationEnd}>
        {isValidArrayWithData(products) && (
          <CustomPagination {...navigationProps} />
        )}
      </Row>
      {isMobile && <Spacer.Horizontal size={32} />}
    </>
  )
}
